.astro-register-container {
    max-width: 70em;
    margin: 100px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .astro-register-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .astro-register-form .form-control {
    border-radius: 5px;
  }
  
  .astro-register-form .btn-primary {
    width: 100%;
    padding: 10px;
  }
  
  

button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    width: 30em;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  .modal-content1{
    background-color: white;
    /* padding: 20px; */
    width: 30em;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  /* @media only screen and (max-width: 600px) {
    .modal-content1{
      background-color: white;
      width: 18em;
      border-radius: 5px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      text-align: center;
    }
  } */
  .modal-content button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .modal-content button:hover {
    background-color: #0056b3;
  }
  
  

  /* Style the form container with background, padding, and box-shadow */
.form-container {
  background-color: #ffffff; 
  padding: 28px; 
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); 
  transition: all 0.3s ease;
}

/* Hover effect for the form */
/* .form-container:hover {
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15); 
} */
.form-container:hover {
  box-shadow: 0px 1px 1px 3px #ff66005e;
}

/* Styling form labels and inputs */
.form-label {
  font-weight: 600;
  margin-bottom: 5px;
  font-size: 14px;
}

/* .form-control {
  border-radius: 5px; 
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease; 
} */
.form-control {
  border-radius: 5px;
  box-shadow: inset 0 2px 5px rgb(0 0 0 / 26%);
  transition: all 0.3s ease;
}

.form-control:focus {
  border-color: #007bff; 
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3); 
}

/* Styling invalid feedback */
.invalid-feedback {
  font-size: 12px;
  color: #dc3545; 
}

/* Button styling */
.btn {
  border-radius: 25px; 
  padding: 10px 20px; 
  font-weight: 600;
}

/* Customize the OTP input field */
.otp-input {
  display: flex;
  justify-content: space-between;
}

.otp-input input {
  width: 3.5rem;
  height: 3.5rem;
  margin: 0px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
}



/* Style the heading to be bold */
.bold-heading {
  font-weight: bold;
  font-size: 1.75rem; 
  color: #333; 
  word-spacing: 10px;
  /* text-align: justify !important; */
}

/* Style the unordered list */
.bullet-points {
  list-style-type: disc;
  padding-left: 20px; 
  font-size: 1rem; 
  color: #555; 
  font-weight: 500;
}

/* Customize each list item */
.bullet-points li {
  margin-bottom: 10px; 
}


/* .logo-img{
  width: 500px;
  height: 150px;
  border-radius: 50px;
  padding: 10px;
  margin: 0 auto;
} */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.logo-img {
  width: 120px; 
  animation: bounce 1s ease-in-out infinite;
  
}
@media (max-width: 767px) {
  h4 strong {
    font-size: 14px; 
    margin-left: -50px;
  }
}